import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DateService } from 'outshared-lib';
import { CookieOptionsInterface } from './cookie-options.interface';
@Injectable({
    providedIn: 'root',
})
export class CookieStorage implements Storage {
    [index: number]: string;

    [key: string]: any;
    private cookieDomain: string;
    private cookieSetting: any;

    length: number;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private cookieService: CookieService,
        private dateService: DateService
    ) {
        if (this.document.location.hostname === 'localhost') {
            this.cookieDomain = 'localhost';
        } else {
            this.cookieDomain = `.${this.document.location.hostname}`;
        }
    }

    public clear(): void {
        this.cookieService.deleteAll();
    }

    public getItem(key: string): string {
        return this.cookieService.get(key);
    }

    public key(index: number): string {
        return this.cookieService.getAll().propertyIsEnumerable[index];
    }

    public removeItem(key: string, options?: CookieOptionsInterface): void {
        const cookieOptions = Object.assign(
            {
                domain: this.cookieDomain,
            },
            options
        );
        this.cookieService.delete(key, cookieOptions.path, cookieOptions.domain, cookieOptions.secure);
    }

    public setItem(key: string, data: string, options?: CookieOptionsInterface): void {
        const cookieOptions: CookieOptionsInterface = {
            domain: this.cookieDomain,
            expires: 365,
            ...options,
        };

        if (key === 'ls.cookie.setting') {
            this.cookieSetting = data;
        }
        this.cookieService.set(
            key,
            data,
            cookieOptions.expires,
            cookieOptions.path,
            cookieOptions.domain,
            cookieOptions.secure
        );
    }

    public getObject(key: string): Object {
        const value = this.getItem(key);
        if (value === null || value === undefined) {
            return undefined;
        } else if (value === '') {
            return {};
        }
        return JSON.parse(value);
    }

    public setObject(key: string, value: object, options?: CookieOptionsInterface): void {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

        const cookieOptions = Object.assign({
            expires: this.dateService.add(1, 'year'),
            ...options,
        });

        return this.setItem(key, JSON.stringify(value), cookieOptions);
    }
}
