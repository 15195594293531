import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionStorage implements Storage {
    [index: number]: string;
    [key: string]: any;

    length: number;

    constructor() {}

    public clear(): void {
        sessionStorage.clear();
    }

    getItem(key: string): string {
        return sessionStorage.getItem(key);
    }

    key(index: number): string {
        return sessionStorage.key(index);
    }

    removeItem(key: string): void {
        sessionStorage.removeItem(key)
    }

    setItem(key: string, value: any): void {
        sessionStorage.setItem(key, value);
    }
}
